<template>
  <div
    class="heating__wrapper"
    :class="{ x2: images.title === 'x2' }"
  >
    <div
      class="heating__prev"
      @click="prevSlide"
    >
      <img src="@/assets/icons/swiperLeft.svg" />
    </div>
    <Swiper
      ref="heaterSwiper"
      class="heating__wrapper-swiper"
      :slides-per-view="1"
      :options="heatingOption"
    >
      <swiper-slide
        v-for="(item, index) in images.images"
        :key="index"
      >
        <img
          class="heating__img"
          style="margin: auto; min-height: 150px"
          :src="require('@/assets/img/heating/' + item)"
        />
      </swiper-slide>
    </Swiper>
    <div
      class="heating__next"
      @click="nextSlide"
    >
      <img src="@/assets/icons/swiperRight.svg" />
    </div>
    <h2 class="heating__wrapper-title">{{ images.title }}</h2>
  </div>
</template>

<script
  lang="ts"
  scoped
>
import Vue from "vue"
export default Vue.extend({
  data() {
    return {
      heatingOption: {
        loop: true,
        centeredSlides: true,
      },
    }
  },
  props: {
    images: {
      type: Object,
      required: true,
    },
    // title: {
    //   type: String,
    //   required: true,
    // },
  },
  // computed: {
  //     swiper() {
  //         return this.$refs.heaterSwiper;
  //     },
  // },
  methods: {
    prevSlide() {
      this.$refs.heaterSwiper.swiperInstance.slidePrev()
    },
    nextSlide() {
      this.$refs.heaterSwiper.swiperInstance.slideNext()
    },
  },
})
</script>

<style lang="scss">
.heating__wrapper {
  max-width: 150px;
  width: 100%;
  position: relative;
  max-height: 245px;
  height: 100%;
  &.x2 {
    margin-top: 0px !important;
    .heating__wrapper-title {
      margin-top: 30px;
    }
    .heating__img {
      max-height: 220px;
    }
    .heating__prev,
    .heating__next {
      top: 42%;
    }
  }
  img {
    max-width: 105px;
  }
  &-title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: #2a2c2c;
  }
}

.heating__prev,
.heating__next {
  position: absolute;
  z-index: 99;
  top: 40%;
  transform: translateY(-50%);
}
.heating__next {
  right: 0;
}
@media (max-width: 800px) {
  .heating {
    &__wrapper {
      margin: 10px 30px;
    }
    &__next {
      right: 10%;
    }
    &__prev {
      left: 10%;
    }
    &-second {
      &__next {
        right: 10% !important;
      }
      &__prev {
        left: 10%;
      }
    }
  }
}
@media (max-width: 600px) {
  .heating {
    &__wrapper {
      max-height: 500px;
      max-width: 300px;
      margin: 0;
      &-swiper {
        width: 100%;
      }
      img {
        // width: 100%;
      }
      &.x2 {
        margin-top: 20px !important;
        .heating__wrapper-title {
          margin-top: 30px;
        }
        .heating__prev,
        .heating__next {
          top: 38%;
        }
      }
    }
  }
  .heating__big__top {
    margin-bottom: 80px;
  }
}
</style>
