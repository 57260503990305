<template>
  <div>
    <div class="heating__main">
      <div class="container">
        <div class="heating__main-header">
          <h4
            class="heating__main-title"
            v-html="$t('heating.heatingTitle')"
          ></h4>
          <p
            class="heating__main-subtitle"
            v-html="$t('heating.heatingSubtitle')"
          ></p>
        </div>

        <div
          class="heating__main-footer"
          v-html="$t('heating.mainSubtitle')"
        ></div>
        <!-- <div class="heating__main-right">
          <img src="@/assets/img/heating/heater.png" />
        </div> -->
      </div>
    </div>
    <div class="heating__explosion">
      <div class="container">
        <h2
          class="heating__explosion-text"
          v-html="$t('heating.bigTitle')"
        ></h2>
        <img
          class="heating__explosion-img"
          src="@/assets/img/heating/disassembled.png"
        />
      </div>
    </div>
    <div class="heating__statistics">
      <div class="container">
        <div class="heating__statistics-texts">
          <h2 class="heating__statistics-texts-title">{{ $t("heating.heatingTemp") }}</h2>
          <p class="heating__statistics-texts-text">
            {{ $t("heating.heatingDesc") }}
          </p>
        </div>
        <div class="heating__statistics-stats">
          <div class="heating__statistics-stats-item">
            <div class="heating__statistics-stats-item-image">
              <img src="@/assets/img/heating/greenStat.png" />
            </div>
            <p>{{ $t("heating.heatingFirst") }}</p>
          </div>
          <div class="heating__statistics-stats-item">
            <div class="heating__statistics-stats-item-image">
              <img src="@/assets/img/heating/redStat.png" />
            </div>
            <p>{{ $t("heating.heatingSecond") }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="heating__advantages">
      <div class="container">
        <div class="heating__advantages-top">
          <div class="heating__advantages-top__item">
            <img
              class="heating__advantages-top__item-image"
              src="@/assets/img/heating/check.png"
            />
            <h2 class="heating__advantages-top__item-title">
              {{ $t("heating.stats.item1") }}
            </h2>
          </div>
          <div class="heating__advantages-top__item">
            <img
              class="heating__advantages-top__item-image"
              src="@/assets/img/heating/check.png"
            />
            <h2 class="heating__advantages-top__item-title">{{ $t("heating.stats.item2") }}</h2>
          </div>
          <div class="heating__advantages-top__item">
            <img
              class="heating__advantages-top__item-image"
              src="@/assets/img/heating/check.png"
            />
            <h2 class="heating__advantages-top__item-title">
              {{ $t("heating.stats.item3") }}
            </h2>
          </div>
        </div>
        <div class="heating__advantages-bottom">
          <p v-html="$t('heating.statsPs')"></p>
        </div>
      </div>
    </div>
    <div class="heating__format">
      <div class="container">
        <h2 class="heating__format-title">{{ $t("heating.formatTitle") }}</h2>
        <div class="heating__format-texts">
          <div class="heating__format-texts__item">
            <h2 class="heating__format-texts__item-title">{{ $t("heating.formatTitle1") }}</h2>
            <p
              class="heating__format-texts__item-text"
              v-html="$t('heating.formatText1')"
            ></p>
          </div>
          <div class="heating__format-texts__item">
            <h2 class="heating__format-texts__item-title">{{ $t("heating.formatTitle2") }}</h2>
            <p
              class="heating__format-texts__item-text"
              v-html="$t('heating.formatText2')"
            ></p>
          </div>
        </div>
      </div>
    </div>
    <div class="heating__big">
      <div class="container">
        <div class="heating__big__top">
          <h2 class="heating__big__top-title">{{ $t("heating.devicesTitle") }}</h2>
          <div class="heating__big__top-sliders">
            <heating-slider
              style=""
              :images="hyper"
            />
            <heating-slider
              style=""
              :images="hyperplus"
            />
            <heating-slider
              style=""
              :images="glopro"
            />
            <heating-slider
              style=""
              :images="gloproslim"
            />
            <heating-slider
              style=""
              :images="x2"
            />
          </div>
        </div>
        <div class="heating__big__bottom">
          <h2 class="heating__big__bottom-title">{{ $t("heating.sticksTitle") }}</h2>
          <div class="heating__big__bottom-sliders">
            <div class="heating__big__bottom-sliders__slider">
              <div class="heating__big__bottom-sliders__slider-title">
                <img src="@/assets/img/heating/demi.png" />
                <h2>Деми формат</h2>
              </div>
              <heating-second-slider
                :images="demi"
                :title="secondTitle"
              />
            </div>
            <div class="heating__big__bottom-sliders__slider">
              <div class="heating__big__bottom-sliders__slider-title">
                <img src="@/assets/img/heating/nano.png" />
                <h2>nano формат</h2>
              </div>
              <heating-second-slider
                :images="nano"
                :title="secondTitle"
              />
            </div>
          </div>
          <div class="heating__big__bottom-mobile-sliders">
            <div class="heating__big__bottom-mobile-titles">
              <div
                class="heating__big__bottom-mobile-title"
                :class="{ titleActive: activeMobile === 1 }"
                @click="activeMobile = 1"
              >
                <img src="@/assets/img/heating/demi.png" />
                <h2>Деми формат</h2>
              </div>
              <svg
                width="2"
                height="76"
                viewBox="0 0 2 76"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 0L1 76"
                  stroke="#2A2C2C"
                />
              </svg>
              <div
                class="heating__big__bottom-mobile-title"
                :class="{ titleActive: activeMobile === 2 }"
                @click="activeMobile = 2"
              >
                <img src="@/assets/img/heating/nano.png" />
                <h2>nano формат</h2>
              </div>
            </div>
            <div class="heating__big__bottom-mobile-slider">
              <heating-second-slider
                :images="demi"
                :title="secondTitle"
                v-if="activeMobile === 1"
              />
              <heating-second-slider
                :images="nano"
                :title="firstTitle"
                v-else-if="activeMobile === 2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="heating__caution">
      <div class="container">
        <p
          class="heating__caution-text"
          v-html="$t('heating.mainSubtitle')"
        ></p>
        <img
          class="heating__caution-image"
          src="@/assets/img/heating/21.svg"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue"
import HeatingSecondSlider from "../../components/sliders/HeatingSecondSlider.vue"
import HeatingSlider from "../../components/sliders/HeatingSlider.vue"
export default Vue.extend({
  components: { HeatingSlider, HeatingSecondSlider },
  name: "Heating",
  data() {
    return {
      hyperplus: {
        images: ["hyperplus/1.png", "hyperplus/2.png", "hyperplus/3.png", "hyperplus/4.png"],
        title: "HYPER+",
      },
      glopro: {
        images: ["glopro/1.png", "glopro/2.png", "glopro/3.png", "glopro/4.png", "glopro/5.png"],
        title: "pro",
      },
      gloproslim: {
        images: ["slim/1.png", "slim/2.png"],
        title: "PRO SLIM",
      },
      hyper: {
        images: ["hyper/1.png", "hyper/2.png", "hyper/3.png", "hyper/4.png"],
        title: "HYPER+ UNIQ",
      },
      x2: {
        images: ["x2/1.png", "x2/2.png", "x2/3.png", "x2/4.png", "x2/5.png"],
        title: "x2",
      },

      demi: [
        {
          title: "KZ DEMI KENT Red Boost NEW R",
          image: "demi/KZ DEMI KENT Red Boost NEW R.png",
        },
        {
          title: "KZ Demi KENT Tobacco NEW R",
          image: "demi/KZ Demi KENT Tobacco NEW R.png",
        },
        {
          title: "KZ DEMI KENT Tropic Click NEW R",
          image: "demi/KZ DEMI KENT Tropic Click NEW R.png",
        },
        {
          title: "KZ Demi KENT Violet Click NEW R",
          image: "demi/KZ Demi KENT Violet Click NEW R.png",
        },
        {
          title: "KZ Demi Melody Click NEW R",
          image: "demi/KZ Demi Melody Click NEW R.png",
        },
        {
          title: "KZ Demi Purple Click NEW R",
          image: "demi/KZ Demi Purple Click NEW R.png",
        },
        {
          title: "KZ Demi Red Boost NEW R",
          image: "demi/KZ Demi Red Boost NEW R.png",
        },
        {
          title: "KZ Demi Tropic Click NEW R",
          image: "demi/KZ Demi Tropic Click NEW R.png",
        },
        {
          title: "KZ Demi Violet Click NEW R",
          image: "demi/KZ Demi Violet Click NEW R.png",
        },
        {
          title: "neo™ Satin Tobacco",
          image: "demi/DIZ_KZ-pack_Satin-Tobacco_400х658.png",
        },
        {
          title: "neo™ Terracotta Tobacco",
          image: "demi/DIZ_KZ-pack_Terracotta-Tobacco_400х658.png",
        },
      ],
      nano: [
        {
          title: "KZ Nano Boost Red NEW R",
          image: "nano/KZ Nano Boost Red NEW R.png",
        },
        {
          title: "KZ Nano Boost Royale NEW R",
          image: "nano/KZ Nano Boost Royale NEW R.png",
        },
        {
          title: "KZ Nano Boost Scarlet NEW R",
          image: "nano/KZ Nano Boost Scarlet NEW R.png",
        },
        {
          title: "KZ Nano Boost Tropic NEW R",
          image: "nano/KZ Nano Boost Tropic NEW R.png",
        },
        {
          title: "KZ Nano Bright Tobacco NEW R",
          image: "nano/KZ Nano Bright Tobacco NEW R.png",
        },
        {
          title: "KZ NANO Creamy Tobacco NEW R",
          image: "nano/KZ NANO Creamy Tobacco NEW R.png",
        },
        {
          title: "KZ Nano Dark Fresh NEW R",
          image: "nano/KZ Nano Dark Fresh NEW R.png",
        },
        {
          title: "kz Vogue Fresh Boost R",
          image: "nano/kz Vogue Fresh Boost R.png",
        },
        {
          title: "kz Vogue Royal Red R",
          image: "nano/kz Vogue Royal Red R.png",
        },
        {
          title: "Vogue Arctic Boost",
          image: "nano/DIZ_KZ-pack_NANO_Arctic_boost_400х658.png",
        },
      ],
      firstTitle: "lol",
      secondTitle: "neo™ Demi Red Boost капсулалы",
      activeMobile: 2,
    }
  },
  mounted() {
    if (!this.$cookies.get("profile")) {
      this.$router.push({
        path: this.getTo("/"),
        query: this.getQuery(),
      })
    }
    if (localStorage.getItem("bat")) {
      localStorage.removeItem("bat")
      this.$router.go()
    }
  },
  methods: {
    getTo(to) {
      const locale = this.$i18n.locale

      if (typeof to !== "string") {
        // this.to.path = `${locale}/${this.to.path}`;
        return to
      }
      return `/${locale}/${to.replace(/^\/|\/$/g, "")}`
    },
    getQuery() {
      return { city_id: this.$route.query.city_id }
    },
  },
})
</script>
<style
  lang="scss"
  scoped
>
.heating {
  &__caution {
    position: sticky;
    bottom: 0;
    z-index: 99;
  }
}

.heating__big__bottom {
  margin-top: 80px !important;
}
</style>
